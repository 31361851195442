<template>
  <div class="resourceSharing">
    <div class="content-box">
      <div class="search-box">
        <img
          class="search-img"
          src="@/assets/resourceSharing/banner1.png"
          alt=""
        />
      </div>
      <div class="search-title">
        <span>共搜索到{{ dataList.length }}条</span>
        <span class="keyworld">“{{ searchForm.keywords }}”</span>
        <span>相关数据信息</span>
      </div>
      <div class="resourceSharing-list">
        <data-list :dataList="dataList" />
        <div class="kong" v-if="dataList.length <= 0">
          <el-empty
            image="https://eduapi.114kaili.com/Content/images/no_data.png"
            :image-size="303"
            description="暂无数据"
          ></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataList from "./components/resourceSharingList";
import Api from "@/api";
export default {
  components: {
    dataList,
  },
  data() {
    return {
      searchForm: {
        keywords: "",
        type: 99,
        fk_category_id: 0,
        fk_grade_id: 0,
        fk_subject_id: 0,
        page: 1,
        pageSize: 200,
      },
      dataList: [],
    };
  },
  mounted() {
    if (this.$route.query.keywords) {
      this.searchForm.keywords = this.$route.query.keywords;
    }
    this.initPage();
  },
  methods: {
    initPage() {
      Api.Resource(this.searchForm).then((res) => {
        if (res.status == 1) {
          this.dataList = res.data || [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 64px;
  border: none;
}
.kong {
  width: 100%;
  margin: 0 auto;
  padding: 100px 0;
  background-color: #fff;
  border-radius: 10px;
}
.resourceSharing {
  width: 100%;
  background: url("../../assets/resourceSharing/banner.png") no-repeat;
  background-color: #f5f5f5;
  background-position: center top;

  .content-box {
    width: 1200px;
    margin: 0 auto;
    .search-box {
      padding-top: 30px;
      width: 603px;
      display: flex;
      flex-direction: column;
      .search-img {
        width: 60%;
        height: auto;
      }
    }
    .search-title {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;

      color: #000000;
      padding: 90px 0 34px;
      .keyworld {
        font-size: 20px;
        color: #1d2088;
      }
    }
    .resourceSharing-list {
      width: 100%;
      padding-bottom: 170px;
    }
  }
  .nav-box {
    width: 100%;
    height: 142px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 53px;
    z-index: 10;
    position: relative;
    .nav-list {
      background-color: #fff;
      width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10;
      .nav-item {
        width: 310px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;

        font-weight: 400;
        color: #000000;
        cursor: pointer;
        .item-icon {
          width: 40px;
          height: 36px;
          margin-right: 13px;
        }
        &.active {
          border-radius: 10px;
          background: #0086ff;
          color: #fff;
        }
      }
    }
  }
}
</style>
